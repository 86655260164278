import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Web3 from 'web3';
import detectEthereumProvider from '@metamask/detect-provider';
import './App.css';
import Numero from './Numero';
import Dexscreener from './Dexscreener';

const contractAddress = '0x598642F59c0366643C6F9ee3252cBB3Ef1524C51';
const contractABI = [
  {
    "constant": true,
    "inputs": [{"name": "_owner", "type": "address"}],
    "name": "balanceOf",
    "outputs": [{"name": "balance", "type": "uint256"}],
    "type": "function"
  }
];

function App() {
  const [account, setAccount] = useState(null);
  const [hasToken, setHasToken] = useState(false);
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    const checkAccount = async () => {
      const provider = await detectEthereumProvider();
      if (provider) {
        const web3 = new Web3(provider);
        const accounts = await web3.eth.requestAccounts();
        setAccount(accounts[0]);

        const contract = new web3.eth.Contract(contractABI, contractAddress);
        const balance = await contract.methods.balanceOf(accounts[0]).call();
        const formattedBalance = Math.floor(Number(balance));
        setBalance(formattedBalance);
        setHasToken(formattedBalance >= 1);
      } else {
        console.error('Please install MetaMask!');
      }
    };

    checkAccount();
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={
          <div>
            {account ? (
              hasToken ? (
                <div className="container">
                  <h1 className="header">Welcome to the OGT Holders Area</h1>
                  <img src="/OGT.png" alt="OGT Logo" className="ogt-logo" />
                  <p className="info">Here is the restricted information...</p>
                  <p className="info">Your Wallet Address: {account}</p>
                  <p className="info">Your OGT Balance: {balance}</p>
                  <div className="projects">
                    <h2>Futuros proyectos de OGT</h2>
                    <div className="project-grid">
                      <Link to="/Numero" className="project-item">
                        <h3>Numero Secreto</h3>
                        <p>Próximamente premios con OGT</p>
                      </Link>
                      <div className="project-item">
                        <h3>Búsqueda del Tesoro</h3>
                        <p>Próximamente</p>
                      </div>
                      <div className="project-item">
                        <h3>NFT Básicos</h3>
                        <p>Próximamente</p>
                      </div>
                      <div className="project-item">
                        <h3>Juegos de trivia</h3>
                        <p>Próximamente</p>
                      </div>
                      <div className="project-item">
                        <h3>Airdrop</h3>
                        <p>Próximamente</p>
                      </div>
                      <div className="project-item">
                        <h3>Proyecto 1</h3>
                        <p>Próximamente</p>
                      </div>
                      <div className="project-item">
                        <h3>Proyecto 2</h3>
                        <p>Próximamente</p>
                      </div>
                    </div>
                  </div>
                  <Dexscreener />
                  <p className="footer">
                    OGT love ❤️ <a href="https://github.com/israelubeda" target="_blank" rel="noopener noreferrer">GitHub</a>
                  </p>
                </div>
              ) : (
                <div className="container">
                  <h1 className="header">Access Denied</h1>
                  <p className="info">You need at least 1 OGT to access this page.</p>
                </div>
              )
            ) : (
              <div className="container">
                <h1 className="header">Connect your wallet</h1>
                <p className="info">Please connect your MetaMask wallet to access this page.</p>
              </div>
            )}
          </div>
        } />
        <Route path="/Numero" element={<Numero />} />
      </Routes>
    </Router>
  );
}

export default App;
