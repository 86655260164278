import React from 'react';
import './Dexscreener.css';

const Dexscreener = () => {
  return (
    <div id="dexscreener-embed">
      <iframe src="https://dexscreener.com/bsc/0xC8fC1fB23b22bDeE251142feee31DcE3C4C8Ab98?embed=1" title="Dexscreener"></iframe>
    </div>
  );
};

export default Dexscreener;
