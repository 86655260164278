import React, { useState, useEffect } from 'react';
//import './Numero.css';

const Numero = () => {
  const [numeroSecreto, setNumeroSecreto] = useState(0);
  const [intentos, setIntentos] = useState(1);
  const [listaNumerosSorteados, setListaNumerosSorteados] = useState([]);
  const [mensaje, setMensaje] = useState('');
  const numeroMaximo = 10;

  useEffect(() => {
    generarNumeroSecreto();
  }, []);

  const asignarTextoElemento = (texto) => {
    setMensaje(texto);
  };

  const verificarIntento = () => {
    const numeroDeUsuario = parseInt(document.getElementById('valorUsuario').value);
    if (numeroDeUsuario === numeroSecreto) {
      asignarTextoElemento(`Acertaste el número en ${intentos} ${(intentos === 1) ? 'vez' : 'veces'}`);
      document.getElementById('reiniciar').removeAttribute('disabled');
    } else {
      if (numeroDeUsuario > numeroSecreto) {
        asignarTextoElemento('El número secreto es menor');
      } else {
        asignarTextoElemento('El número secreto es mayor');
      }
      setIntentos(intentos + 1);
      limpiarCaja();
    }
  };

  const limpiarCaja = () => {
    document.getElementById('valorUsuario').value = '';
  };

  const generarNumeroSecreto = () => {
    let numeroGenerado = Math.floor(Math.random() * numeroMaximo) + 1;
    if (listaNumerosSorteados.length === numeroMaximo) {
      asignarTextoElemento('Ya se sortearon todos los números posibles');
    } else {
      if (listaNumerosSorteados.includes(numeroGenerado)) {
        return generarNumeroSecreto();
      } else {
        setListaNumerosSorteados([...listaNumerosSorteados, numeroGenerado]);
        setNumeroSecreto(numeroGenerado);
        setIntentos(1);
      }
    }
  };

  const reiniciarJuego = () => {
    limpiarCaja();
    generarNumeroSecreto();
    document.getElementById('reiniciar').setAttribute('disabled', 'true');
  };

  return (
    <div className="container">
      <div className="container__contenido">
        <div className="container__informaciones">
          <div className="container__texto">
            <h1>Juego del número secreto!</h1>
            <p className="texto__parrafo">{`Indica un número del 1 al ${numeroMaximo}`}</p>
            <p className="texto__parrafo">{mensaje}</p>
          </div>
          <input type="number" id="valorUsuario" min="1" max="10" className="container__input" />
          <div className="chute container__botones">
            <button onClick={verificarIntento} className="container__boton">Intentar</button>
            <button onClick={reiniciarJuego} className="container__boton" id="reiniciar" disabled>Nuevo juego</button>
          </div>
        </div>
        <img src="./img/ia.png" alt="Una persona mirando a la izquierda" className="container__imagen-persona" />
      </div>
    </div>
  );
};

export default Numero;
